.section--blue-circles {
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 530px;
    height: 530px;
    background: #99bef1;
    border-radius: 50%;
    border: 43px solid#b5cdf1;
  }

  &::before {
    bottom: -265px;
    left: -265px;
  }

  &::after {
    top: -265px;
    right: -265px;
  }
}

.section-home-categories__header {
  margin: 0 0 $andes-spacing-24;
  text-align: center;

  h2.andes-typography--size-xl.andes-typography--type-title {
    margin: 0 0 $andes-spacing-12;
  }

  p.andes-typography--size-m.andes-typography--type-body {
    margin: 0;
  }
}

.section-home-categories__items {
  display: flex;
  flex-wrap: wrap;
  gap: $andes-spacing-16;
}

.section-home-categories__item {
  position: relative;
  width: 308px;
  height: 182px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
}

.section-home-categories__item__link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: $andes-spacing-24 $andes-spacing-12 $andes-spacing-32;

  &:hover {
    justify-content: center;
    padding-top: 0;
    padding-bottom: 44px;

    svg {
      display: none;
    }

    .section-home-categories__item__bg {
      opacity: 1;
    }

    > div h3.andes-typography.andes-typography--type-title {
      margin: 0 0 $andes-spacing-16;
      color: $andes-white;
    }

    > div span.andes-typography--size-s.andes-typography--type-body {
      display: block;
    }

    .section-home-categories__item__bus {
      opacity: 1;
    }

    .section-home-categories__item__link {
      justify-content: flex-start;
    }
  }
}

.section-home-categories__item
  h3.andes-typography.andes-typography--type-title {
  position: relative;
  margin: $andes-spacing-16 0 0;
}

.section-home-categories__item
  span.andes-typography--size-s.andes-typography--type-body {
  position: relative;
  margin: 0;
  display: none;
}

.section-home-categories__item__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.2s;
  background-color: #5194f9;
  background-repeat: no-repeat;
  background-size: 100%;

  &--marketing-y-publicidad,
  &--marketing-e-publicidade {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/Appstore/271134960326-stage-6.jpg');
  }

  &--vehiculos,
  &--automoveis {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/Appstore/271134960326-stage-21.jpg');
  }

  &--inmuebles,
  &--imoveis {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/Appstore/271134960326-stage-22.jpg');
  }

  &--gestion-de-ecommerce,
  &--gestao-de-ecommerce {
    background-image: none;
  }

  &--finanzas-y-contabilidad,
  &--financas-e-contabilidade {
    background-image: none;
  }

  &--educacion,
  &--educacao {
    background-image: none;
  }

  &--otros-servicios,
  &--outros-servicos {
    background-image: none;
  }

  &--pagos,
  &--pagamentos {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/Appstore/271134960326-stage-10.jpg');
  }
}

.section-home-categories__item__bus {
  align-items: center;
  background-color: $andes-bg-white;
  border-radius: 10px 10px 0 0;
  bottom: 0;
  display: flex;
  left: 50%;
  opacity: 0;
  padding: 10px 19px;
  position: absolute;
  transform: translate(-50%);
}
