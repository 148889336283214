.section-home-especializadas {
  h2.andes-typography--size-xl.andes-typography--type-title {
    font-size: $font-size-20;
    line-height: 25px;
    margin-bottom: $andes-spacing-16;
  }

  &__slider {
    padding-bottom: $andes-spacing-40;
  }

  .custom-tab {
    width: 135px !important;

    .andes-tab__link {
      padding: 10px 0;
    }
  }

  .andes-tab-content {
    padding: $andes-spacing-16 0 $andes-spacing-4 0;
  }

  .section-home-especializadas__slider {
    padding-bottom: 0;
  }

  .card-app-item__wrapper {
    width: 210px;
  }
}
