@import 'op-frontend-commons/src/components/modal/index';

.rating-modal-container {
  border-radius: 0 0 6px 6px;

  &--review-input {
    padding: $andes-spacing-28 0;
    background: $andes-gray-070-solid;
  }

  .review-container--hidden {
    display: none;
  }
}
