.card-app-item {
  width: 200px;

  a {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    height: 100%;
  }
}

.card-app-item__wrapper {
  height: 100%;
}

.card-app-item__header {
  h2 {
    margin: 0;

    &.andes-typography.andes-typography--type-body {
      font-weight: $font-weight-semibold;
    }
  }
}

.card-app-item__content {
  min-height: 45px;
  margin-top: $andes-spacing-16;
}

.card-app-item__content span {
  // margin: $andes-spacing-12 0 $andes-spacing-16;
  // min-height: 60px;

  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-app-item__main-title {
  display: flex;
  align-items: center;
  gap: $andes-spacing-8;
  min-height: 70px;

  .card-app-item__main-texts {
    .andes-typography.andes-typography--type-body {
      font-weight: $font-weight-semibold;
    }

    span.andes-typography.andes-typography--type-body.andes-typography--weight-regular {
      margin-top: 4px;
    }
  }
}

.card-app-item__main-category {
  margin: $andes-spacing-16 0 0 0;
  height: 30px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.card-app-item__footer {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: flex-end;

  .card-app-item__footer-recommended-badge {
    display: flex;
    align-items: center;
    margin-top: $andes-spacing-24;
  }
}
