@import '~@andes/form/base';
@import '~@andes/textfield/base';
@import '~@andes/autocomplete/index';

.search-form {
  max-width: 632px;
  width: 100%;
}

.andes-autocomplete--cdp {
  .andes-form-control {
    padding-top: 0;
  }

  .andes-dropdown.andes-dropdown--form {
    top: 52px;
  }

  .andes-form-control__bottom {
    display: none;
  }
}

.op-login .loggedout-block,
.op-login .loggedin-trigger {
  padding: 0 10px;
}

.search-form-container-mobile {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 76px;
  background: $andes-white;
  padding: $andes-spacing-24 $andes-spacing-16 $andes-spacing-20
    $andes-spacing-24;
  z-index: 1;
  left: -12px;
  right: auto;
  top: 0;

  svg {
    margin: 0 $andes-spacing-16 0 0;

    path {
      fill: $andes-blue-base;
    }
  }

  .nav-search-btn {
    right: auto;
    left: 0;
    top: 2px;
  }

  .andes-form-control--textfield .andes-form-control__field {
    min-height: $andes-spacing-32;
    margin: 0 0 0 $andes-spacing-24;
    min-width: 281px;
  }

  .andes-form-control--textfield .andes-form-control__control {
    min-height: $andes-spacing-32;
    border-radius: $andes-spacing-20;
    margin: 0 0 0 $andes-spacing-16;
  }

  .andes-autocomplete {
    .andes-button__content *,
    .andes-form-control--search-box:hover {
      display: flex;
      width: 80vw;
    }

    .andes-form-control--search-box--always-visible {
      width: 80vw;
    }

    .andes-dropdown.andes-dropdown--form {
      width: 100vw;

      .andes-dropdown__popover {
        position: relative;
        left: -62px;
        width: 101%;
        top: 0;
        border-radius: 0;

        .andes-list__item {
          padding: 14px $andes-spacing-16 14px $andes-spacing-32;
          color: $andes-gray-900;
        }
      }
    }
  }
}
