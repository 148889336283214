.section-home-featured-apps {
  position: relative;
  background: #2d3277;
  color: $andes-white;
  padding: 100px 0;
  overflow: hidden;

  .container::after {
    content: '';
    width: 536px;
    height: 536px;
    position: absolute;
    top: -$andes-spacing-8;
    right: -100px;
    z-index: -1;
    background: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/CDP/242710282625-bg-gradient-circle-ms.png')
      no-repeat;
    display: block;
  }

  p.andes-typography--size-l.andes-typography--type-body {
    margin: $andes-spacing-32 0 0;
  }
}

.section-home-featured-apps {
  .container {
    display: flex;
    gap: 100px;
  }
}

.section-home-featured-apps__list {
  display: flex;
  max-width: 731px;
  flex-wrap: wrap;
  gap: $andes-spacing-16;

  .card-app-item {
    width: 233px;

    .andes-typography {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.section-home-featured-apps__header {
  display: inline-flex;
  flex: 1;
  flex-flow: column wrap;
  justify-content: space-between;

  img {
    display: block;
    margin: $andes-spacing-40 auto 0;
  }
}

.section-home-featured-apps__subtitle {
  display: block;
  margin: $andes-spacing-24 0 0;
}
