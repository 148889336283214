.section-home-featured-apps {
  .container {
    flex-wrap: wrap;

    &::after {
      display: none;
    }
  }

  p.andes-typography--size-l.andes-typography--type-body {
    font-size: $font-size-14;
    line-height: 18px;
    margin: $andes-spacing-12 0 0;
  }
}

.section-home-featured-apps__list {
  .card-app-item {
    width: 100%;
  }

  .logo-bus {
    margin-top: 0;
  }
}

.section-home-featured-apps__header {
  h2.section-home-featured-apps__title {
    font-size: $font-size-20;
    line-height: 25px;
  }

  span.section-home-featured-apps__subtitle {
    font-size: $font-size-14;
    line-height: 15px;
    margin: $andes-spacing-12 0 0;
  }

  p.section-home-featured-apps__description
    .andes-typography.andes-typography--type-body.andes-typography--weight-regular {
    font-size: $font-size-14;
    font-weight: $font-weight-bold;
  }
}
