@import './stages__large';

@media (width <= 1300px) {
  .section-home-categories__items {
    justify-content: center;
  }
}

@media (width <= 768px) {
  @import './stages__small';
}
