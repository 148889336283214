.section-home-recommendations__area {
  flex-flow: column wrap;

  &::after {
    display: none;
  }
}

.section-home-recommendations__content {
  margin: 0 0 $andes-spacing-24;
}

.section-home-recommendations__content-controls-none {
  display: none;
}

.section-home-recommendations__slider,
.section-home-recommendations__content {
  width: 100%;
  height: auto;
}

.andes-carousel-snapped__pagination--position-inner {
  position: initial;
}

.section-home-recommendations
  h2.andes-typography--size-xl.andes-typography--type-title {
  font-size: $font-size-20;
  line-height: 25px;
}

.section-home-recommendations
  span.andes-typography--size-m.andes-typography--type-body {
  font-size: $font-size-12;
  line-height: 15px;
  margin-top: $andes-spacing-16;
}

.section-home-recommendations
  p.andes-typography--size-l.andes-typography--type-body {
  font-size: $font-size-14;
  line-height: 18px;
  margin-top: 26px;
}
