$andes-theme: 'mercadolibre' !default;

@import '~@andes/common/variables';

@import '~@andes/card/index';
@import '~@andes/button/index';
@import '~@andes/carousel-snapped/index';
@import '~@andes/carousel-free/index';
@import '~@andes/thumbnail/index';
@import '~@andes/typography/index';
@import '~@andes/tabs/index';
@import '~@andes/badge/index';

@import '../../styles/common';

@import 'components/BannerView/banner';
@import 'components/StagesView/stages';
@import 'components/FeaturedAppsView/featured-apps';
@import 'components/EspecializadasView/especializadas';
@import 'components/RecommendationNewSellerView/recommendation-new-seller';
@import 'components/CertificationProgramsView/certification-programs';

@import './home__large';

@media (width <= 768px) {
  @import './home__small';
}
