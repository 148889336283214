@import 'op-frontend-commons/src/components/rating-star/index';
@import 'op-frontend-commons/src/components/rating-viewer/index';

.rating-viewew-app-container {
  display: flex;
  flex-direction: column;
}

.start-review-wrapper {
  display: flex;
  align-items: center;
  margin-top: $andes-spacing-24;

  &:hover {
    cursor: pointer;
  }

  &__new-tag {
    margin-left: $andes-spacing-8;
    background-color: $andes-blue-500;
    color: $andes-white;
    border-color: $andes-blue-500;
  }
}
