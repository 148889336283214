.section--blue-circles::before,
.section--blue-circles::after {
  display: none;
}

.section-home-categories__header {
  text-align: left;

  h2.andes-typography--size-xl.andes-typography--type-title {
    font-size: $font-size-20;
    line-height: 25px;
  }

  p.andes-typography--size-m.andes-typography--type-body {
    font-size: $font-size-14;
    line-height: 15px;
  }
}

.section-home-categories__items {
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-left: -$andes-spacing-20;
  margin-right: -$andes-spacing-20;
  padding: 0 $andes-spacing-20;
  justify-content: flex-start;
}

.section-home-categories__item {
  height: auto;

  svg {
    z-index: 1;
  }
}

.section-home-categories__item__link {
  justify-content: center;
  padding-top: 0;
  padding-bottom: 44px;

  .icon-fill {
    fill: $andes-white;
  }

  &:hover {
    svg {
      display: block;
    }
  }
}

.section-home-categories__item__bg {
  opacity: 1;
}

.section-home-categories__item
  h3.andes-typography.andes-typography--type-title {
  margin: 0 0 $andes-spacing-16;
  color: $andes-white;
}

.section-home-categories__item
  span.andes-typography--size-s.andes-typography--type-body {
  display: block;
}

.section-home-categories__item__bus {
  opacity: 1;
}
