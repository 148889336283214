.certification-badge {
  display: flex;
  align-items: center;
  margin-top: 24px;

  &__description {
    margin-left: 8px;

    span {
      display: block;

      &:first-child {
        text-transform: capitalize;
      }

      &:last-child {
        margin-top: 8px;
      }
    }
  }

  &--pill {
    &.andes-badge--pill {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
    }

    &::before {
      content: '';
      background-repeat: no-repeat;
      background-size: cover;
      width: 15px;
      height: 18px;
      margin-left: $andes-spacing-12;
    }

    .andes-badge__content {
      padding-right: $andes-spacing-12;
    }
  }

  &--pill-platinum {
    &.andes-badge--accent {
      background-color: $platinum-bg;
    }

    &::before {
      width: 17px;
      height: 18px;
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/324277804513-small-platinum.png');
    }

    .andes-badge__content {
      color: $platinum-font;
    }
  }

  &--pill-gold {
    &.andes-badge--accent {
      background-color: $gold-bg;
    }

    &::before {
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/324278021525-small-gold.png');
    }

    .andes-badge__content {
      color: $gold-font;
    }
  }

  &--pill-silver {
    &.andes-badge--accent {
      background-color: $silver-bg;
    }

    &::before {
      background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/320143761532-medalla-silver-small.png');
    }

    .andes-badge__content {
      color: $silver-font;
    }
  }
}
