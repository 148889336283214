.section-home-recommendations {
  background: $andes-bg-white;
  overflow: hidden;

  .container {
    display: flex;
    justify-content: space-between;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.section-home-recommendations__content {
  max-width: 435px;
}

.section-home-recommendations
  h2.andes-typography--size-xl.andes-typography--type-title {
  margin: 0;
}

.section-home-recommendations
  span.andes-typography--size-m.andes-typography--type-body {
  display: block;
  margin: $andes-spacing-12 0 0;
}

.section-home-recommendations
  p.andes-typography--size-l.andes-typography--type-body {
  margin: $andes-spacing-16 0 0;
}

.section-home-recommendations__content-controls {
  max-width: 372px;
  margin-top: $andes-spacing-40;
  width: 100%;
}

.section-home-recommendations__slider {
  width: 530px;
  height: 322px;
  position: relative;
  z-index: 1;
}

.section-home-recommendations__area {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &::after {
    content: '';
    width: 207px;
    height: 207px;
    border-radius: 50%;
    border-width: 30px;
    border-style: solid;
    display: block;
    position: absolute;
    bottom: -30px;
    right: -90px;
    z-index: 0;
    transition: 0.5s;
    box-sizing: border-box;
  }
}

.section-home-recommendations__area--ml::after {
  background: rgb(255 209 0 / 50%);
  border-color: rgb(255 209 0 / 50%);
}

.section-home-recommendations__area--mp::after {
  background: #99bef1;
  border-color: #b5cdf1;
}

.section-home-recommendations__area--ms::after {
  background: #ea9cc3;
  border-color: #ebb8d1;
}
