.section {
  &.section-home-banner {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    height: 512px;
    margin: 0;
    padding-top: $andes-spacing-56;
    padding-bottom: 0;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgb(51 51 51 / 80%) 0%,
        rgb(51 51 51 / 0%) 100%
      );
    }

    .container {
      height: 100%;
    }
  }
}

.section-home-banner__text {
  display: flex;
  height: 100%;
  flex-flow: column wrap;
  justify-content: center;
  padding-bottom: 0;

  .andes-typography--size-l.andes-typography--type-title {
    max-width: 578px;
    margin: 0;

    @media (width >= 768px) {
      font-size: $font-size-44;
      line-height: $font-size-44;
    }
  }

  .andes-typography--size-l.andes-typography--type-body {
    max-width: 350px;
    margin: $andes-spacing-24 0 0;
  }
}
