@import './variables';
@import '../components/FooterView/footer';
@import '../components/HeaderView/header';
@import '../components/SearchForm/search-form';
@import '../components/CardAppItem/card-app-item';
@import '../components/CertificationBadge/certification-badge';
@import '../components/Reviews/RatingViewer/rating-viewer';
@import '../components/Reviews/ReviewCommentsModal/review-comments-modal';
@import '../components/Reviews/RatingModal/rating-modal';
@import '../components/Reviews/ReviewForm/review-form';
@import '../components/Reviews/CancelConfirmationBox/cancel-confirmation-box';
@import '../components/Reviews/RatingLoader/rating-loader';

html,
body {
  font-family: $font-family-primary;
  background-color: $andes-bg-primary;
  font-size: $font-size-base;
  height: 100%;

  [role='main'] {
    display: block;
  }
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a,
a:active,
a:hover,
a:link,
a:visited {
  text-decoration: none;
}

// Business Units
.logo-bus {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: $andes-spacing-16;
}

.logo-bu {
  width: 34px;
  height: 24px;
  margin-right: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }
}

.logo-bu--mlibre {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/247461708770-Mercado-Libre.png');
}

.logo-bu--mshops {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/247461708770-Mercado-Libre.png');
}

.logo-bu--mpago {
  background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/247461681663-Mercado-Pago.png');
}

.container {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  z-index: 1;
}

@media (width <= 1280px) {
  .container {
    padding: 0 $andes-spacing-20;
  }
}
