.section-home-certification {
  overflow: hidden;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: $andes-spacing-16;
  }
}

.section-home-certification__header {
  flex-direction: column;
  width: 100%;
  margin-bottom: $andes-spacing-8;
}

.section-home-certification__items {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: $andes-spacing-16;
}

// Notice Card
.notice-card {
  width: 308px;

  > a {
    display: flex;
    flex-flow: column wrap;
    height: 100%;
  }

  .notice-card__header {
    padding: 0;

    &__img {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }

  .notice-card__title {
    min-height: 44px;
    display: block;
  }

  .notice-card__content {
    padding: 16px;
  }

  .notice-card__description {
    margin: 12px 0 0;
  }

  .notice-card__footer {
    display: flex;
    flex: 1;
    align-items: flex-end;
    padding: 5px 16px 16px;
  }
}
