.nav-header {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica,
    Roboto, Arial, sans-serif;
  background: $andes-white;
  padding: 0 $andes-spacing-12;
}

.nav-header .nav-bounds {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 12px 0;

  span.andes-typography.andes-typography--type-body.andes-typography--weight-regular {
    font-weight: $font-weight-semibold;
    margin: 0 0 0 49px;
  }
}

.nav-logo__container {
  display: flex;
  height: 24px;
  width: 100%;
}

.nav-logo {
  background-image: url('https://http2.mlstatic.com/ui/navigation/3.0.8/mercadolibre/logo__small.png');
  background-repeat: no-repeat;
  display: flex;
  position: absolute;
  height: 28px;
  overflow: hidden;
}

@media (min-device-pixel-ratio: 2),
  (resolution >= 192dpi),
  (resolution >= 2dppx) {
  .nav-logo {
    background-image: url('https://http2.mlstatic.com/ui/navigation/3.0.8/mercadolibre/logo__small@2x.png');
    background-size: 39px 28px;
  }

  .nav-logo-mp {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/247461708770-Mercado-Libre.png');
    background-size: cover;
    width: 140px;
    height: inherit;
    top: 9px;
  }
}

.nav-search {
  position: absolute;
  top: 0;
  left: 51px;
  height: 50px;
  padding: $andes-spacing-8 0;
  transition: left 0.15s ease-out;
}

.nav-header-menu-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 60%;
}

.nav-header-menu-wrapper-mobile {
  padding: 3px 0 0;
  border: none;
  background: none;

  .search-form {
    display: inherit;
  }
}

input[type='text'].nav-search-input {
  color: #333;
  font-size: 16px;
  border-radius: 2px;
  height: 100%;
  margin: 0;
  background-color: #fff;
  padding: 9px 60px 8px 15px;
  box-shadow: none;
  font-family: inherit;
  border: 1px solid #ccc;
}

input[type='text'].nav-search-input,
input[type='text'].nav-search-input:focus {
  border-radius: 6px;
}

button.nav-search-btn {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  height: 100%;
  padding: 0 15px;
  color: #d2d2d2;
}

.op-login,
.andes-floating-menu {
  display: flex;
}

.andes-floating-menu {
  margin: 0 0 0 $andes-spacing-24;
}

.andes-list__group--size-medium {
  width: 100%;
}

.nav-header-menu-login {
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;
}
