.section {
  &.section-home-banner {
    height: 317px;
    margin: 50px 0 0;
    padding: 0;
  }
}

.section-home-banner__text {
  justify-content: flex-end;
  padding: 0 0 74px;

  .andes-typography--size-l.andes-typography--type-title {
    max-width: 325px;
  }

  .andes-typography--size-l.andes-typography--type-body {
    max-width: 205px;
    margin-top: $andes-spacing-16;
  }

  p.andes-typography--size-s.andes-typography--type-body {
    font-size: $font-size-20;
    font-weight: $font-weight-semibold;
  }
}
