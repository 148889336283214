.card-app-item {
  width: 100%;

  &__wrapper {
    flex: 1;
  }

  &__footer {
    flex-direction: row;
  }

  .card-app-item__main-category span,
  .card-app-item__content span {
    font-size: $font-size-14;
    font-style: normal;
  }
}
