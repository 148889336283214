.nav-footer {
  display: flex;
  padding: $andes-spacing-20 $andes-spacing-64;

  .user-name {
    display: none;
  }

  .nav-footer-copyright {
    display: flex;
    border-bottom: none;
    margin: 0 $andes-spacing-32 0 0;
    padding: 0;
    width: auto;
  }

  .nav-footer-navigation {
    margin: 0;
    padding: 0;

    ul {
      display: flex;

      li {
        display: inline-flex;
        padding: 0 10px;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}
