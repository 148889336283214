$modal-rating-reviews-max-height: 564px;
$content-rating-reviews-min-height: 480px;

.modal-rating-reviews-sheet-header {
  margin-left: $andes-spacing-32;
}

.modal-rating-reviews-sheet {
  margin: $andes-spacing-8 $andes-spacing-40;
  min-height: $content-rating-reviews-min-height;
}

.cdpa-custom-height .commons-modal-container__overlay {
  max-height: $modal-rating-reviews-max-height;
}

@media (width <= 768px) {
  .cdpa-custom-height .commons-modal-container__overlay {
    max-height: 100%;
  }
}
