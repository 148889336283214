.section-home-certification {
  .container {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.section-home-certification__items {
  flex-wrap: wrap;
  justify-content: center;
  margin: 60px 0 0;
}

.notice-card {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.andes-typography--size-xs .andes-typography--type-body {
  font-size: $font-size-14;
}
