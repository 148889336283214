.section-home-especializadas {
  background: $andes-bg-white;
  overflow: hidden;

  h2.andes-typography--size-xl.andes-typography--type-title {
    margin: 0 0 $andes-spacing-24;
  }

  .andes-tabs__border {
    display: none;
  }

  .andes-tab-content {
    display: flex;
    gap: 16px;
    padding-left: 0;
    padding-right: 0;
  }

  .andes-carousel-snapped__container--content.andes-carousel-snapped__container--with-controls.andes-carousel-snapped__container--strict-boundaries {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .card-app-item {
    height: 320px;
  }
}

// Custom Tabs
.custom-tabs {
  gap: $andes-spacing-16;
  margin-bottom: $andes-spacing-8;
}

.custom-tab {
  background: $andes-bg-secondary;
  box-shadow: 0 1px 2px rgb(0 0 0 / 12%);
  border-radius: 20px;
  width: 173px !important;

  .andes-tab__link {
    padding: $andes-spacing-12 0;

    &::after {
      display: none;
    }
  }

  &.custom-tab--ml:hover,
  &.custom-tab--ml:hover:not(.andes-tab--disabled).andes-tab--selected,
  &.custom-tab--ml.andes-tab--selected {
    background: $yellow-bg;
    color: $andes-blue-800;
  }

  &.custom-tab--ms:hover,
  &.custom-tab--ms:hover:not(.andes-tab--disabled).andes-tab--selected,
  &.custom-tab--ms.andes-tab--selected {
    background: $magenta_bg;
    color: $andes-white;
  }

  &.custom-tab--mp:hover,
  &.custom-tab--mp:hover:not(.andes-tab--disabled).andes-tab--selected,
  &.custom-tab--mp.andes-tab--selected {
    background: $blue-bg;
    color: $andes-white;
  }
}
