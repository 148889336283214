.nav-footer {
  font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica,
    Roboto, Arial, sans-serif;
  background-color: $andes-bg-secondary;
  border-bottom: 1px solid #dcdcdc;
  display: block;

  .user-name {
    display: block;
    width: 100%;
    font-size: $font-size-12;
    text-align: center;
    color: #999;
    background-color: $andes-bg-white;
    padding: 21px 0;

    span {
      color: #333;
    }

    ul {
      margin: 0 auto;
      padding: 0;

      li {
        padding: 0 $andes-spacing-16;
        list-style: none;
        display: inline-block;

        a {
          color: #333;
        }

        &:first-child {
          border-right: 1px solid #ccc;
        }
      }
    }
  }

  .nav-footer-copyright {
    margin: 0 auto;
    display: block;
    text-align: center;
    border-bottom: solid 1px #ddd;
    padding: $andes-spacing-16 0;
    width: 100%;

    small {
      font-size: $font-size-12;
      color: #999;
    }
  }

  .nav-footer-navigation {
    display: block;
    margin: 0 auto;
    padding: $andes-spacing-16 0 34px;
    text-align: center;

    ul {
      margin: 0 auto;
      padding: 0;

      li {
        padding: 0 10px;
        list-style: none;
        display: inline-block;

        a {
          font-size: $font-size-12;
          color: $andes-blue-500;
        }

        &:first-child {
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}
