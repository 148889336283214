.nav-header {
  position: fixed;
  z-index: 900;
  background: transparent;
  border: 0;
  height: auto;
  padding: 13px $andes-spacing-20;
  transition: 0.3s;
  width: 100%;
  min-height: 68px;

  .nav-bounds {
    padding: 0 0 0 335px;
    height: auto;
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
  }

  .nav-header-menu-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .nav-logo {
    top: 8px;
    text-indent: inherit;
    background: none;
    gap: $andes-spacing-12;
    width: auto;
    height: auto;
    left: 0;

    span {
      color: $andes-white;
      margin: 0;
    }
  }

  .nav-logo::before {
    content: '';
    width: 34px;
    height: 24.5px;
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/247461708770-Mercado-Libre.png');
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
  }

  .nav-logo-mp {
    top: -8px;
  }

  .nav-logo-mp::before {
    background-image: url('https://http2.mlstatic.com/storage/developers-site-cms-admin/212559804316-cdpa-logo-mp.png');
    width: 150px;
    height: 45px;
  }

  .nav-header-menu-wrapper {
    .nav-search {
      position: relative;
      max-width: 632px;
      width: 100%;
      display: inline-flex;
      left: 0;
    }
  }

  &.nav-header--scroll {
    background: $andes-bg-white;

    .nav-logo span {
      color: #000;
    }

    #nav-header-menu a {
      color: $andes-black;
    }
  }
}

input[type='text'].nav-search-input {
  width: 100%;
}

.nav-header-user {
  margin-top: 10px;
  height: 30px;
  line-height: 30px;
}

#nav-header-menu {
  height: auto;

  a {
    border-left: 0;
    line-height: 40px;
    color: $andes-white;

    &:hover {
      color: $andes-white;
    }
  }

  [for='nav-header-user-switch'] {
    margin-left: $andes-spacing-16;
    margin-right: $andes-spacing-16;
  }
}

.nav-search {
  padding: 0;
  height: auto;
}

.nav-bounds {
  max-width: 1280px;
}

.nav-header .nav-bounds {
  padding-bottom: 0;

  span.andes-typography.andes-typography--type-body.andes-typography--weight-regular {
    margin: 0;
  }
}

.nav-logo__container {
  width: 0;
}
