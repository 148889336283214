@import './certification-programs__large';

@media (width <= 1300px) {
  .section-home-certification {
    .container {
      flex-wrap: wrap;
      justify-content: center;
      gap: $andes-spacing-32;
    }
  }
}

@media (width <= 768px) {
  @import './certification-programs__small';
}
