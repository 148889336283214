.search-form {
  padding-right: $andes-spacing-16;
}

.andes-form-control--search-box--size-large {
  min-height: 42px;
  padding: 13px;
  font-size: 14px;
  border-radius: 0.375em;
}
